<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div
        class="myHeader_vertical"
        ref="myHeader_cotizadorDatos"
        id="myHeader_cotizadorDatos"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top"></div>
        </div>
        <div class="cotizadorDatosTitle1 title title_margin_top">
          {{ t("app.cotizadorDatos.tit") }}
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_cotizadorDatos"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_cotizadorDatos"
        v-show="cont_visible"
        @scroll="onScroll"
        ref="contenido_cotizadorDatos"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="cotizadorDatosText1 text_basico f_w_500">
            {{ t("app.cotizadorDatos.text_1_a") }} &nbsp;
            <span>{{ dead_line }}&nbsp;</span>
          </div>

          <!-- <div class="cotizadorDatosAviso">
            <div class="cotizadorDatosAvisoTitle">
              {{ t("app.cotizadorDatos.sub_tit_2") }}
            </div>
            <div class="cotizadorDatosAvisoText">
              {{ t("app.cotizadorDatos.text_2_a") }} &nbsp;
              <span>{{ t("app.cotizadorDatos.text_2_b") }}</span> &nbsp;
              {{ t("app.cotizadorDatos.text_2_c") }} &nbsp;
              <span>{{ t("app.cotizadorDatos.text_2_d") }}</span> &nbsp;
              {{ t("app.cotizadorDatos.text_2_e") }}
            </div>
          </div> -->

          <div class="cotizadorDatosTitle2">
            {{ t("app.cotizadorDatos.sub_tit_3") }}
          </div>

          <div class="cotizadorDatosBcos">
            <div
              class="cotizadorDatosBco"
              v-for="(bco, i) in dataBancos"
              :key="i"
            >
              <div class="cotizadorDatosItem">
                <cardCotizadorDatosBancarios_v2 :datos="bco" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_cotizadorDatos"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer_estrecho_centrado" ref="footer_cotizadorDatos">
        <div class="footer_estrecho">
          <div class="cotizadorDatosBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.entiendo')"
              @action="continuar"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
            />
          </div>

          <div class="cotizadorDatosEtapas">
            <pasosGiro paso="6" totales="6" />
          </div>

          <div class="cotizadorDatosFooter"></div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import cardCotizadorDatosBancarios_v2 from "../components/simulador/cardCotizadorDatosBancarios_v2.vue";
import { mapMutations, mapState } from "vuex";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: "cotizadorDatos",
  components: {
    cardCotizadorDatosBancarios_v2,
    btnAction,
    pasosGiro,
    blurComp,
    screen_width_cont,
  },
  data() {
    return {
      // iconCotizadorVolver: require("../assets/imgs/iconCotizadorVolver.svg"),
      header: 0,
      footer: 0,
      cont_visible: false,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_cotizadorDatos: {},
      blur_sup_cotizadorDatos: {},
      blur_inf_cotizadorDatos: {},
    };
  },
  computed: {
    ...mapState([
      "configuracion",
      "transaccion",
      "remitente",
      "heigthScreen",
      "dataResumen",
      "widthScreen",
      "plataforma",
    ]),

    dataBancos() {
      let aux = [];
      if (this.configuracion && this.transaccion && this.remitente) {
        aux = [...this.configuracion.data.auxiliares.datos_bancarios];
      }
      aux.forEach((banco) => {
        banco["monto_pagar"][1] = `$ ${this.transaccion.monto_origen}`;
        let props = Object.keys(banco);
        for (let index = 0; index < props.length; index++) {
          const propiedad = props[index];
          if (propiedad != "banco_nombre") {
            banco[propiedad][0] = this.t(
              `variables.datos_bancarios.${propiedad}`
            );
          }
        }
      });

      return aux;
    },

    estadoBtn() {
      let aux = "active";

      return aux;
    },
    dead_line() {
      let ahora = new Date();
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      ahora.setTime(ahora.getTime() + 12 * 60 * 60 * 1000);
      let horas =
        ahora.getHours() < 10 ? `0${ahora.getHours()}` : ahora.getHours();
      let minutos =
        ahora.getMinutes() < 10 ? `0${ahora.getMinutes()}` : ahora.getMinutes();
      return `${ahora.toLocaleDateString(this.locale, options)} ${this.t(
        "app.cotizadorDatos.dead_line"
      )} ${horas}:${minutos}.`;
    },
  },
  methods: {
    ...mapMutations(["updateBloquearNavegacion", "setshowSpinner"]),

    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    continuar() {
      this.setshowSpinner(false);
      this.$router.push({ name: "cotizadorFinalizar", replace: true });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    heigthScreen() {
      this.contenido_cotizadorDatos = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_cotizadorDatos"].offsetHeight;
        this.footer = this.$refs["footer_cotizadorDatos"].offsetHeight;
        this.cont_visible = true;
        this.contenido_cotizadorDatos = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_cotizadorDatos = blur_sup_style(this.header);
        this.blur_inf_cotizadorDatos = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_cotizadorDatos"].scrollHeight >
          this.$refs["contenido_cotizadorDatos"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
};
</script>

<style>
.cotizadorDatosTitle1 {
  text-align: center;
  letter-spacing: 0.5px;
  color: #801b1c;
}
.cotizadorDatosText1 {
  text-align: center;
  color: #787878;
}
.cotizadorDatosText1 span {
  color: #801b1c;
  font-weight: 900;
}
.cotizadorDatosTitle2 {
  margin-top: 2rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: #614ad3;
}
.cotizadorDatosBcos {
  margin-top: 0.3125rem;
}
.cotizadorDatosBco {
  margin-top: 0.9375rem;
}
.cotizadorDatosItem {
  margin-top: 1rem;
}
.cotizadorDatosAviso {
  margin-top: 1.5rem;
  background: #ffffff;
  box-shadow: 0px 1px 11px rgba(28, 29, 52, 0.1);
  border-radius: 15px;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.cotizadorDatosAvisoTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #801b1c;
}
.cotizadorDatosAvisoText {
  margin-top: 0.9375rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.5px;
  color: #801b1c;
}
.cotizadorDatosAvisoText span {
  font-weight: 700;
}
.cotizadorDatosBtn {
  width: 100%;
  margin-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.cotizadorDatosEtapas {
  margin-top: 1rem;
}

.cotizadorDatosFooter {
  margin-top: 1.25rem;
  text-align: center;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  color: #787878;
  padding-bottom: 1.25rem;
}
.cotizadorDatosFooterDestacado {
  text-decoration: underline;
}
</style>