<template>
  <div class="cotiz_banc_data_card">
    <div
      class="cotiz_banc_data_card_img"
      v-if="datos.banco_nombre[0] == 'Banco Estado'"
    >
      <img :src="bco_estado" alt="" />
    </div>
    <div
      class="cotiz_banc_data_card_img"
      v-if="datos.banco_nombre[0] == 'Banco Santander'"
    >
      <img :src="bco_santander" alt="" />
    </div>
    <!-- <p>{{datos.banco_nombre}}{{}}</p> -->
    <div class="cotiz_banc_data_card_item">
      <div class="cotiz_banc_data_card_item_1">{{ datos.razon_social[0] }}</div>
      <div class="cotiz_banc_data_card_item_2">{{ datos.razon_social[1] }}</div>
    </div>
    <div class="cotiz_banc_data_card_item">
      <div class="cotiz_banc_data_card_item_1">{{ datos.rut[0] }}</div>
      <div class="cotiz_banc_data_card_item_2">{{ datos.rut[1] }}</div>
    </div>
    <div class="cotiz_banc_data_card_item">
      <div class="cotiz_banc_data_card_item_1">
        {{ datos.numero_cuenta[0] }}
      </div>
      <div class="cotiz_banc_data_card_item_2">
        {{ datos.numero_cuenta[1] }}
      </div>
    </div>
    <div class="cotiz_banc_data_card_item">
      <div class="cotiz_banc_data_card_item_1">{{ datos.mail[0] }}</div>
      <div class="cotiz_banc_data_card_item_2">{{ datos.mail[1] }}</div>
    </div>
    <div class="cotiz_banc_data_card_item">
      <div class="cotiz_banc_data_card_item_1">{{ datos.monto_pagar[0] }}</div>
      <div class="cotiz_banc_data_card_item_2">{{ datos.monto_pagar[1] }}</div>
    </div>
    <!-- <div class="cotiz_banc_data_card_item">
      <div class="cotiz_banc_data_card_item_1">
        {{ datos.rut_remitente[0] }}
      </div>
      <div class="cotiz_banc_data_card_item_2">
        {{ datos.rut_remitente[1] }}
      </div>
    </div> -->
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: "cardCotizadorDatosBancarios_v2",
  props: {
    datos: Object,
  },
  data() {
    return {
      bco_estado: require("../../assets/imgs/cotizador/bco_estado.png"),
      bco_santander: require("../../assets/imgs/cotizador/santander.png"),
    };
  },
  computed: {},
  methods: {},
};
</script>

<style>
.cotiz_banc_data_card {
  /* border: 1px solid var(--customC-614AD3);
  border-radius: 10px; */
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 1px 11px rgba(28, 29, 52, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.cotiz_banc_data_card_img {
  /* width: 100%; */
  height: 2.7rem;
  padding-bottom: 1rem;
}
.cotiz_banc_data_card_img img {
  /* width: 100%; */
  height: 100%;
}
.cotiz_banc_data_card_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #801b1c;
}
.cotiz_banc_data_card_item_1 {
  font-weight: 400;
}
.cotiz_banc_data_card_item_2 {
  
  font-weight: 700;
}
</style>